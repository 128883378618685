<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p>
        Indicate whether aqueous solutions made from the following salts would have pH values less
        than, equal to, or above 7.
      </p>

      <v-simple-table>
        <thead>
          <tr>
            <th><stemble-latex content="$\text{Salt}$" /></th>
            <th>
              <stemble-latex content="$\text{pH} < 7$" />
            </th>
            <th>
              <stemble-latex content="$\text{pH} = 7$" />
            </th>
            <th>
              <stemble-latex content="$\text{pH} > 7$" />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="i in 5" :key="'row-' + i">
            <td>
              <chemical-latex :content="molecules[i - 1]" />
            </td>
            <td>
              <v-radio-group v-model="inputs[molecules[i - 1]]" :disabled="!allowEditing">
                <v-radio class="pl-3" key="lessThan" value="lessThan" />
              </v-radio-group>
            </td>
            <td>
              <v-radio-group v-model="inputs[molecules[i - 1]]" :disabled="!allowEditing">
                <v-radio class="pl-3" key="equalTo" value="equalTo" />
              </v-radio-group>
            </td>
            <td>
              <v-radio-group v-model="inputs[molecules[i - 1]]" :disabled="!allowEditing">
                <v-radio class="pl-3" key="greaterThan" value="greaterThan" />
              </v-radio-group>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'Question540',
  components: {ChemicalLatex, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        Na2CO3: null,
        NaHSO4: null,
        NH4ClO4: null,
        KBr: null,
        CH3COONa: null,
      },
      molecules: ['Na2CO3', 'NaHSO4', 'NH4ClO4', 'KBr', 'CH3COONa'],
    };
  },
};
</script>
